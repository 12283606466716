// extracted by mini-css-extract-plugin
export var antSteps = "logo-module--ant-steps--9PdrB";
export var antStepsDot = "logo-module--ant-steps-dot--r-JQ-";
export var antStepsHorizontal = "logo-module--ant-steps-horizontal--8czAm";
export var antStepsItemDescription = "logo-module--ant-steps-item-description--tgaJA";
export var antStepsItemIcon = "logo-module--ant-steps-item-icon--b5qVl";
export var antStepsItemTitle = "logo-module--ant-steps-item-title--HU-k2";
export var antStepsSmall = "logo-module--ant-steps-small--YCif1";
export var antTypography = "logo-module--ant-typography--k2GcX";
export var common = "logo-module--common--eygAw";
export var hideOnReducedMotion = "logo-module--hideOnReducedMotion--BxLQ-";
export var noBullet = "logo-module--noBullet--NLqCt";
export var primary = "logo-module--primary--cGWw7";
export var secondary = "logo-module--secondary--rpNpt";
export var showOnReducedMotion = "logo-module--showOnReducedMotion--sZjpN";
export var tertiary = "logo-module--tertiary--AgKd1";
export var text = "logo-module--text--JcUrL";
export var tweetBox = "logo-module--tweet-box--5hOba";