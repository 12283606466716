// extracted by mini-css-extract-plugin
export var antSteps = "mailchimp-module--ant-steps--UeP15";
export var antStepsDot = "mailchimp-module--ant-steps-dot--GOlX4";
export var antStepsHorizontal = "mailchimp-module--ant-steps-horizontal--R7nFG";
export var antStepsItemDescription = "mailchimp-module--ant-steps-item-description--XBTmn";
export var antStepsItemIcon = "mailchimp-module--ant-steps-item-icon--WeBgh";
export var antStepsItemTitle = "mailchimp-module--ant-steps-item-title--4c6Y7";
export var antStepsSmall = "mailchimp-module--ant-steps-small--Ned+7";
export var antTypography = "mailchimp-module--ant-typography--Hl3KG";
export var hideOnReducedMotion = "mailchimp-module--hideOnReducedMotion--5XI2U";
export var noBullet = "mailchimp-module--noBullet--6d+hZ";
export var showOnReducedMotion = "mailchimp-module--showOnReducedMotion--Ww7tG";
export var signUpForm = "mailchimp-module--signUpForm--Reqj+";
export var tweetBox = "mailchimp-module--tweet-box--MbCb1";