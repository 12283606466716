// extracted by mini-css-extract-plugin
export var antSteps = "star-begging-module--ant-steps--Tbd05";
export var antStepsDot = "star-begging-module--ant-steps-dot--pFyQS";
export var antStepsHorizontal = "star-begging-module--ant-steps-horizontal--DQ5Z8";
export var antStepsItemDescription = "star-begging-module--ant-steps-item-description--p+AoW";
export var antStepsItemIcon = "star-begging-module--ant-steps-item-icon--DuOKL";
export var antStepsItemTitle = "star-begging-module--ant-steps-item-title--hNJ8G";
export var antStepsSmall = "star-begging-module--ant-steps-small--VFKc3";
export var antTypography = "star-begging-module--ant-typography--r+v7M";
export var close = "star-begging-module--close--Q+ebM";
export var hideOnReducedMotion = "star-begging-module--hideOnReducedMotion--RlyJ8";
export var noBullet = "star-begging-module--noBullet--zr7L6";
export var off = "star-begging-module--off--ysAOh";
export var on = "star-begging-module--on--iJT5W";
export var showOnReducedMotion = "star-begging-module--showOnReducedMotion--0hAP9";
export var starBegging = "star-begging-module--starBegging--Wq95v";
export var tweetBox = "star-begging-module--tweet-box--Vt9OU";