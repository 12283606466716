// extracted by mini-css-extract-plugin
export var antSteps = "footer-module--ant-steps---zcWl";
export var antStepsDot = "footer-module--ant-steps-dot--PDGRj";
export var antStepsHorizontal = "footer-module--ant-steps-horizontal--twInj";
export var antStepsItemDescription = "footer-module--ant-steps-item-description--cffSp";
export var antStepsItemIcon = "footer-module--ant-steps-item-icon--9FmZ7";
export var antStepsItemTitle = "footer-module--ant-steps-item-title--Ey62P";
export var antStepsSmall = "footer-module--ant-steps-small--mlOcJ";
export var antTypography = "footer-module--ant-typography--2CUuz";
export var copyright = "footer-module--copyright--ergaY";
export var footer = "footer-module--footer--v4SON";
export var footerWrapper = "footer-module--footerWrapper--tmHEz";
export var hideOnReducedMotion = "footer-module--hideOnReducedMotion--mvn39";
export var newsletterForm = "footer-module--newsletterForm--Wmlyx";
export var noBullet = "footer-module--noBullet--PUqnK";
export var showOnReducedMotion = "footer-module--showOnReducedMotion--HX8xn";
export var tweetBox = "footer-module--tweet-box--FEtPa";